@import "../../../../../template.sass";

.container_photos {
  border: 1px solid $border;
  border-radius: 4px;

  .main_photo {
    background-color: #cccccc;
    height: 295px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover; } }


  .other_photos {
    padding: 20px;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    scroll-padding-right: 20px;
    .photo {
      margin: 0 10px;
      min-width: 59px;
      min-height: 59px;
      width: 59px;
      height: 59px;
      background-color: #cccccc;

      &:last-child {
        margin-right: 20px; }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover; } } } }
