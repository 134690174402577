@charset "utf-8";

$main-color: #3273dc;

$navbar-background-color: $main-color;
$navbar-height: 5.35rem;
$navbar-item-color: #fff;
$navbar-dropdown-arrow: $navbar-item-color;
$navbar-dropdown-background-color: $navbar-background-color;

@import "bulma/bulma.sass";
